import moment from 'moment';
import {
  type Interaction,
  type InteractionType,
  type ImageType,
} from '../types';
import { type TypeOptions, toast } from 'react-toastify';
import { getSlideUrl } from '@services/utils.service';
import { v4 } from 'uuid';

export const getInteractionNameFromType = (
  interactionType: InteractionType
) => {
  switch (interactionType) {
    case 'pollWithOptions':
      return 'Power Polls';
    case 'freeForm':
      return 'Open-Ended Polls';
    case 'map':
      return 'Magic Maps';
    case 'wordCloud':
      return 'Wonder Words';
    case 'wheel':
      return 'Choice Circle';
    case 'emojisEverywhere':
      return 'Emojis Everywhere';
    case 'talkingTiles':
      return 'Talking Tiles';
    case 'transientThought':
      return 'Transient Thoughts';
    case 'newWheel':
      return 'Winning Wheel';
    case 'linkLibrary':
      return 'Link Library';
    case 'Quick Questions':
      return 'Quick Questions';
    case 'textTrack':
      return 'Text Track';
    case 'PDF Slide':
      return 'PDF Slide';
    case 'ratingPolls':
      return 'Rating Polls';
    case 'fantasticFans':
      return 'Fantastic Fans';
    default:
      return '';
  }
};

export const getFormattedDate = (date?: string) => {
  if (!date) return '';
  return moment(date).format('MMM DD, dddd • hh:mm A');
};

export const notify = (message: string, type: TypeOptions = 'success') => {
  toast(message, {
    position: 'bottom-left',
    theme: 'colored',
    autoClose: 3500,
    type,
  });
};

export const calculateRemainingChars = (
  text: string = '',
  limit: number = 255
) => {
  if (typeof text !== 'string') return limit;
  return limit - text.length;
};

export const getPlatformImageURL = (platform: string) => {
  switch (platform) {
    case 'ipa':
      return '/images/in_person.svg';
    case 'hybrid':
      return '/images/hybrid.svg';
    case 'other-online-events':
      return '/images/OtherOnlinePlatforms.svg';
    case 'zoom':
      return '/images/Zoom.svg';
    case 'meet':
      return '/images/meet-icon.svg';
    case 'teams':
      return '/images/teams-logo.svg';
    case 'youtube':
      return '/images/youtube-icon.svg';
    case 'twitch':
      return '/images/twitch-icon.svg';
    case 'vimeo':
      return '/images/vimeo-icon.svg';
    case 'webex':
      return '/images/webex-icon.svg';
    case 'linkedin':
      return '/images/linkedin-icon.svg';
    default:
      return '';
  }
};

export const getDefaultTitle = (interactionType: InteractionType) => {
  switch (interactionType) {
    case 'newWheel':
      return 'And the winner is...';
    case 'linkLibrary':
      return 'StreamAlive found these links in the chat...';
    case 'Quick Questions':
      return 'StreamAlive found these questions in the chat...';
    case 'textTrack':
      return 'Text from the chat...';
    case 'fantasticFans':
      return 'Your top commenters are...';
    case 'ratingPolls':
      return 'What would you rate this session?';
    default:
      return '';
  }
};

export const getCardImage = (question?: Interaction): string => {
  if (!question) return '';
  const imgPath = '/images/ros/template';
  const imgMap: ImageType = {
    pollWithOptions: `${imgPath}/powerpolls.svg`,
    freeForm: `${imgPath}/powerpolls.svg`,
    map: `${imgPath}/map.svg`,
    wordCloud: `${imgPath}/wordcloud.svg`,
    wheel: `${imgPath}/choice.svg`,
    emojisEverywhere: `${imgPath}/emoji-list.svg`,
    talkingTiles: `${imgPath}/talking-tiles.svg`,
    transientThought: `${imgPath}/transient-thoughts.svg`,
    newWheel: `${imgPath}/ww.svg`,
    linkLibrary: `${imgPath}/link.svg`,
    'Quick Questions': `${imgPath}/qq.svg`,
    textTrack: `${imgPath}/text-track.svg`,
    'PDF Slide': getSlideUrl({
      interaction: question,
      isLargePreview: true,
      slide: undefined,
    }),
    ratingPolls: `${imgPath}/rating-polls.svg`,
    fantasticFans: `${imgPath}/fantastic-fans.svg`,
  };
  return imgMap[question.type] || '';
};

export const getDefaultOptions = (interaction_type: InteractionType) => {
  switch (interaction_type) {
    case 'pollWithOptions':
      return [
        { id: v4(), body: 'Option 1' },
        { id: v4(), body: 'Option 2' },
      ];

    case 'wheel':
      return [
        { id: v4(), body: 'Option 1' },
        { id: v4(), body: 'Option 2' },
      ];
    case 'ratingPolls':
      return [
        { id: v4(), body: '1', option_number: 1 },
        { id: v4(), body: '2', option_number: 2 },
        { id: v4(), body: '3', option_number: 3 },
        { id: v4(), body: '4', option_number: 4 },
        { id: v4(), body: '5', option_number: 5 },
      ];
    default:
      return [];
  }
};
